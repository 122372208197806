<template>
  <section class="main">
    <el-form
      :label-position="'left'"
      label-width="150px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 1000px"
    >
      <el-form-item label="subjectCourseId">
        <el-input v-model="paper.subjectCourseId"> </el-input>
      </el-form-item>
      <el-form-item label="id">
        <el-input v-model="paper.id" disabled> </el-input>
      </el-form-item>
      <el-form-item label="练习册标题">
        <el-input v-model="paper.title"> </el-input>
      </el-form-item>
      <el-form-item label="练习册类型">
        <el-input v-model="paper.type" disabled> </el-input>
      </el-form-item>
      <br/>
      <br/>
      <div class="chapter-title">练习册章节</div>
      <br/>
      <br/>
      <div
      v-for="(item, index) in paper.content"
      :key="index"
      >
        <el-row type="flex" class="chapter-content">
          <el-col :span="24">
            <el-form-item label="章节名称">
              <el-input v-model="item.name"> </el-input>
            </el-form-item>
            <el-form-item label="章节描述">
              <el-input v-model="item.desc"> </el-input>
            </el-form-item>
            <el-form-item label="问题ID">
              <el-input v-model="item.questionIds"> </el-input>
            </el-form-item>
            <el-form-item label="章节分数">
              <el-input v-model="item.presetScore"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="button-item">
            <el-row>
              <el-button type="primary" icon="el-icon-circle-plus" @click="onAdd(index)" circle></el-button>
              <el-button type="danger" icon="el-icon-remove" @click="onDelete(index)" circle></el-button>
            </el-row>
          </el-col>
        </el-row>
  
      </div>
      <el-form-item>
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>


    </el-form>
  </section>

</template>

<script>
import { tikuApi } from "@/api/tikuApi";
import msg from "@/util/msg.js";


export default {
  data() {
    return {
      pageStatus: 'edit',
      paper: {
        subjectCourseId: 1,
        id: 0,
        title: '',
        type: 0,
        content: [
          {
            name: '',
            desc: '',
            quesitonIds: '',
            presetScore: 0.0
          }
        ]
      }
    }
  },
  methods: {
    onAdd(index) {
      this.paper.content.splice(index + 1, 0, {name: '', desc: '', questionIds: '', presetScore: 0.0})
    },
    onDelete(index) {
      this.paper.content.splice(index, 1)
    },
    onSave() {
      tikuApi
        .addExerciseBook(this.paper)
        .then(ret => {
          console.log(ret)
          const { code: code, msg: msgStr, data: data } = ret;
          if (code == 0) {
            msg.succeed("保存成功");
            
          } else {
            msg.error("保存失败");
          }
        })
    }
  },
  mounted() {
    const id = this.$route.query.id;
    const subjectCourseId = this.$route.query.subjectCourseId;
    if (id && id > 0 && subjectCourseId && subjectCourseId > 0) {
      this.pageStatus = "edit";
      tikuApi.getExerciseBook(subjectCourseId, id).then((ret) => {
        console.log(ret);
        let { code: code, data: data } = ret;
        if (code == 0) {
          this.paper = data;
        }
      });
    }
  },
 

}

</script>

<style lang="less" scoped>
.chapter-title {
  float: left;
}
.chapter-content {
  border: 1px solid rgb(7, 7, 7);
  margin: 10px;
  padding: 20px;
}
.button-item {
  align-items: center;
  justify-content: center;
}
</style>